import './App.css';
import { useEffect, useState } from 'react';

const KhadijaCover = ({guestName}) => {
  return (
    <header className='center-contents-vertically'>
      <div className='contents'>
        <p>Dear {guestName},</p>
        <p>In the name of Allah, the most beneficent, the most merciful,</p>
        <p>Mr. Ghazali Khan and Mrs. Kishwer Ahmed cordially invite you to celebrate the marriage of their daughter,</p>
        <p className='name'>Khadija Ghazali<br/> Khan</p>
        <p>with</p>
        <p className='name'>Nicholas Anthony<br/> Riley</p>
        <p>son of Mr. Steve Riley and Illona Vitalis-Castro</p>
        <p>Scroll for full details</p>
      </div>
    </header>
  )
}

const NicholasCover = ({guestName}) => {
  return (
    <header className='center-contents-vertically'>
      <div className='contents'>
        <p>Dear {guestName},</p>
        <p className='name'>Nicholas Anthony<br/> Riley</p>
        <p>and</p>
        <p className='name'>Khadija Ghazali<br/> Khan,</p>
        <p>together with their families, cordially invite you to celebrate their wedding.</p>
        <p>Scroll for full details</p>
      </div>
    </header>
  )
}

const EmptyPage = () => <div className='empty-page' />

function subtractMinutes(timeStr, minutes) {
  // Parse the input time string
  let [hours, mins] = timeStr.split(':').map(Number);

  // Convert the time to minutes
  let totalMinutes = hours * 60 + mins;

  // Subtract the specified minutes
  totalMinutes -= minutes;

  // Handle potential negative total minutes (for times before 00:30)
  if (totalMinutes < 0) {
      totalMinutes += 24 * 60; // Add 24 hours in minutes to wrap around
  }

  // Convert back to hours and minutes
  let newHours = Math.floor(totalMinutes / 60);
  let newMinutes = totalMinutes % 60;

  // Format the new time string ensuring two digits for hours and minutes
  let newTimeStr = String(newHours).padStart(2, '0') + ':' + String(newMinutes).padStart(2, '0');

  return newTimeStr;
}

const Event = ({title, date, start, end, isAsian, address, guestID}) => {
  const dateImgPath = date.toLowerCase().includes("28") ? "/28-07.png" : "/04-08.png";
  const adjustedStart = !isAsian ? start : subtractMinutes(start, 30);
  const adjustedName = title.includes("Walima") ? "Walima & Reception" : title;
  const text = title.toLowerCase().includes("mehndi") ? "Aisha Khan and Walia Irfan invite you to celebrate Khadija's Mehndi." : "";

  return(
    <div className='event center-contents-vertically'>
      <div className='contents'>
        <img src={dateImgPath} alt='' />
        <h2>{adjustedName}</h2>
        <p>{text}</p>
        <p>{date} from {adjustedStart} to {end} at {address}</p>
        <a className='rsvp' target='_BLANK' rel="noreferrer" href={`https://rsvp.khadijaandnicholas.com/${guestID}/`}>RSVP</a>
      </div>
    </div>
  );
}

const Cover = ({nicholasGuest, guestName}) => {
  if(nicholasGuest) {
    return <NicholasCover guestName={guestName}/>
  }
  return <KhadijaCover guestName={guestName}/>
}

const Invitation = ({events, nicholasGuest, isAsian, guestID, guestName}) => {

  return <>
    <div className={`fade`}>
      <div className='container'>
        <Cover nicholasGuest={nicholasGuest} guestName={guestName} />
        {
          events.map(
            (event, idx) => 
              <Event
                key={idx}
                isAsian={isAsian}
                title={event.name}
                date={event.date}
                start={event.start}
                end={event.end}
                address={event.address}
                guestID={guestID}
              />
          )
        }
      </div>
    </div>
  </>
}

const NotFound = () => {

  return <>
    <div className={`not-found fade`}>
      <div className='container center-contents-vertically'>
        <div>
          <h1>404 Not Found</h1>
          <p>Please re-check the link you have been provided.</p>
        </div>
      </div>
    </div>
  </>
}
const Loading = () => {
  return <>
    <div className={`not-found fade`}>
      <div className='container center-contents-vertically'>
        <div>
          <h1>Loading...</h1>
        </div>
      </div>
    </div>
  </>
}

const Background = () => {
  return <div className='background'></div>
}

function App() {
  const user = window.location.pathname.slice(1).split("/")[0];
  const [invitation, setInvitation] = useState(null)
  const [invitationFetchErr, setInvitationFetchErr] = useState(null)

  useEffect(() => {
    if(user === ""  || invitation !== null) {
      return;
    }

    fetch(`${process.env.REACT_APP_API_URL}/invitations/${user}`)
    .then(resp => {
      if(resp.status === 200) {
        return resp.json();
      } else {
        setInvitationFetchErr("404: Not Found")
      }
    })
    .then(data => setInvitation(data))
    .catch(e => setInvitationFetchErr("An unexpected error occurred."))
  }, [user, invitation, setInvitation, invitationFetchErr, setInvitationFetchErr])


  // if nothing in the url
  if(user === "") {
    return <NotFound />
  }

  // if there was an error fetching the data
  if(invitationFetchErr !== null) {
    return <NotFound />
  }

  // if there is a string, and there's no error
  if(invitation === null) {
    return <Loading />
  }

  const events = invitation.events;
  const guestName = invitation.name;
  const isAsian = invitation["is_asian"]
  const isNicholasGuest = invitation["is_nicholas_guest"];
  return (
    <div className="App">
      <Background/>
      <Invitation events={events} isAsian={isAsian} nicholasGuest={isNicholasGuest} guestID={user} guestName={guestName} />
      <EmptyPage/>
    </div>
  );
}

export default App;
